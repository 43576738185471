import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
const SearchBlock = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        background: "white",
        height: "auto",
        border: "2px #f5f7f8 solid",
      }}
      className="form-control"
    >
      {props.products.length > 0 ? (
        props.products.map(function (product, index) {
          return (
            <div key={index}>
               <Link
                  to={
                    "/product-" +
                    product.name.replace(/[#+. _]/gi, "-").toLowerCase() +
                    "-" +
                    product.id
                  }
                  className="search-link"
                  title={product.name}
                >
              <div className="row">
               
                  <div className="col-lg-2">
                    <img
                      src={product.featured_image}
                      height="100px"
                      className="img-responsive"
                    />
                  </div>
                  <div className="col-lg-8">
                    <div style={{ marginTop: "10px" }}>
                      {product.brand}, {product.name}, {product.model_number}
                    </div>

                    <div className="cross-price">
                      <span className="currency">NPR</span>
                      <span className="strike">
                        <span>
                          {" "}
                          <NumberFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            value={product.price}
                          />
                        </span>
                      </span>
                    </div>
                    <div className="price no-marign">
                      <span className="currency">NPR</span>
                      <meta itemProp="price" content="299" />
                      <span className="total--sale-price" data-sale-price="299">
                        <span>
                          {" "}
                          <NumberFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            value={product.discount_price}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
              </div>
              </Link>

            </div>
          );
        })
      ) : (
        <div className="text-center">No Search Product Found.</div>
      )}
    </div>
  );
};

export default SearchBlock;
