import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
export const MobileSidebar = ({ width, height, categories }) => {
  const [xPosition, setX] = React.useState(-(width + 30));

  const toggleMenu = () => {
    if (xPosition < 0) {
      setX(0);
    } else {
      setX(-(width + 30));
    }
  };

  React.useEffect(() => {
    setX(-(width + 30));
  }, []);
  return (
    <React.Fragment>
      <span className="mobile-category-button" onClick={() => toggleMenu()}>
        {" "}
        <FontAwesomeIcon icon="bars" style={{ transform:"scale(2)", color:"#0171b6" }} />{" "}
      </span>
      <div
        className="side-bar-mobile"
        style={{
          transform: `translatex(${xPosition}px)`,
          width: width,
          minHeight: height,
          left: "0px",
        }}
      >
        <div className="content mobile-category-div">
          {" "}
          <h1 className="text-center">Catgories</h1>
          {categories.map(function (category, index) {
            return (
              <div className="sidenav">
                <button className="dropdown-btn">
                  {category.name} <FontAwesomeIcon icon="caret-down" />
                </button>
                <div className="dropdown-container">
                  {category.subCategories.map(function (subCategory, index) {
                    return (
                      <React.Fragment>
                        {subCategory.subCategoryTypes.length > 0 ? (
                          <button className="dropdown-btn">
                            {subCategory.name}{" "}
                            <FontAwesomeIcon icon="caret-down" />
                          </button>
                        ) : (
                          <Link
                            to={"/sub-" + subCategory.sub_category_url}
                            aria-haspopup="true"
                            onClick={() => toggleMenu()}
                          >
                            {subCategory.name}
                          </Link>
                        )}

                        <div className="dropdown-container next">
                          {subCategory.subCategoryTypes.length > 0 ? (
                            <React.Fragment>
                              {subCategory.subCategoryTypes.map(function (
                                subCategoryType,
                                index
                              ) {
                                return (
                                  <Link
                                    to={
                                      "/type-" +
                                      subCategoryType.sub_category_type_url
                                    }
                                    onClick={() => toggleMenu()}
                                  >
                                    {subCategoryType.name}
                                  </Link>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                {/* <Link to={"/category/" + category.category_url}>
                            {category.name}
                          </Link> */}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};
