import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../page/Other/NewCustom.css";
import Countdown from "react-countdown";
import CouponApplicationProducts from "./CouponApplicationProducts";
import Modal from "../../Layouts/Modal/Modal";
const CouponLayout = (props) => {
  const [tooltipText, setTooltipText] = useState("Click to Copy");
  function copyCoupon(e) {
    let coupon =
      e.target.parentNode.parentNode.getElementsByTagName("h4")[0].innerHTML;

    navigator.clipboard.writeText(coupon);
    setTooltipText("Copied: " + coupon);
  }

  function outFunc() {
    setTooltipText("Click to Copy");
  }
  const countdownRef = useRef(null);
  const [shouldRenderCountdown, setShouldRenderCountdown] = useState(false);
  useEffect(() => {
    setShouldRenderCountdown(true);
  }, []);
  const Completionist = () => <span>Expired!</span>;
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <>
          <div className="cg-day-2024-countdown-components">
            <span className="cg-day-2024-countdown-component-title">DAY</span>
            <span className="cg-day-2024-countdown-component-time">{days}</span>
          </div>
          <div className="cg-day-2024-countdown-components">
            <span className="cg-day-2024-countdown-component-title">HOUR</span>
            <span className="cg-day-2024-countdown-component-time">
              {hours}
            </span>
          </div>
          <div className="cg-day-2024-countdown-components">
            <span className="cg-day-2024-countdown-component-title">MIN</span>
            <span className="cg-day-2024-countdown-component-time">
              {minutes}
            </span>
          </div>
          <div className="cg-day-2024-countdown-components">
            <span className="cg-day-2024-countdown-component-title">SEC</span>
            <span className="cg-day-2024-countdown-component-time">
              {seconds}
            </span>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="cg-day-2024-section-title">
        <span className="cg-day-2024-title">{props.view.title}</span>
      </div>

      <CouponApplicationProducts />

      <div className="cgday-2024-static-coupons">
        {props.view.coupon_list.map(function (coupon, index) {
          return (
            <div className="cg-day-2024-coupon-wrapper" key={index}>
              <div className="coupon-card">
                <div className="cg-day-2024-discount-percent-and-countdown">
                  <div class="cg-day-2024-discount-percent">
                    {coupon.value}%
                  </div>
                  <div
                    ref={countdownRef}
                    id="countdown-yetaa"
                    class="cg-day-2024-countdown"
                  >
                    {coupon.coupon_timer == 1 &&
                      coupon.date_range &&
                      shouldRenderCountdown && (
                        <Countdown
                          date={Date.now() + coupon.time_remaining * 1000}
                          renderer={renderer}
                        />
                      )}
                  </div>
                </div>
                <div className="cg-day-2024-discount-contents">
                  <h3>{coupon.title}</h3>
                  <p>{coupon.description}</p>
                  <div className="coupon-row">
                    <h4>{coupon.code}</h4>
                    <button
                      id="cpnCode"
                      onClick={copyCoupon}
                      onMouseOut={outFunc}
                    >
                      <span className="tooltiptext">{tooltipText}</span>{" "}
                      {coupon.code}
                    </button>
                  </div>
                  <div className="circle1"></div>
                  <div className="circle2"></div>
                  <div className="circle3"></div>
                  <div className="circle4"></div>
                  <div className="circle5"></div>
                  <div className="circle6"></div>
                  <div className="circle7"></div>
                  <div className="circle8"></div>
                  <div className="circle9"></div>
                  <div className="circle10"></div>
                  <div className="circle11"></div>
                  <div className="circle12"></div>
                  <div className="circle13"></div>
                  <div className="circle14"></div>
                  <div className="circle15"></div>
                  <div className="circle16"></div>
                  <div className="circle17"></div>
                  <div className="circle18"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CouponLayout;
